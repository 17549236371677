import React, { useEffect, useRef, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner"

const formId = process.env.GATSBY_EMAIL_OCTOPUS_FORM_ID as string

export default function Newsletter() {
  const ref = useRef<HTMLDivElement | null>(null)
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const container = ref.current
    let observer: IntersectionObserver

    if (container) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (container && entry.isIntersecting) {
              const script = document.createElement("script")
              script.src = `https://eocampaign1.com/form/${formId}.js`
              script.async = true
              script.setAttribute("data-form", formId)
              script.onload = () => {
                setLoaded(true)
              }

              container.appendChild(script)
              observer.unobserve(entry.target)
            }
          })
        },
        {
          rootMargin: "10% 0px 10% 0px",
          threshold: 0,
        }
      )

      observer.observe(container)
    }

    return () => {
      if (container) {
        observer?.disconnect()

        const nodes = document.querySelectorAll(`[data-form="${formId}"]`)
        nodes.forEach(function (node) {
          if (node.parentNode) {
            node.parentNode.removeChild(node)
          }
        })
      }
    }
  }, [])

  return (
    <div className="relative overflow-hidden isolate bg-neutral-inverse-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17.12500rem"
        height="25.12500rem"
        fill="none"
        className="absolute bottom-0 hidden lg:block -z-10"
        viewBox="0 0 274 402"
      >
        <path
          fill="url(#paint0_linear_1288_14088)"
          fillOpacity="0.15"
          d="M51.397 4.16a40.97 40.97 0 00-34.794 0L-142.71 78.877a40.972 40.972 0 00-22.493 27.745l-38.943 166.166a40.971 40.971 0 008.191 35.305l109.625 133.877a40.97 40.97 0 0031.699 15.014h177.264a40.971 40.971 0 0031.699-15.014l109.625-133.877a40.971 40.971 0 008.191-35.305l-38.943-166.166a40.97 40.97 0 00-22.493-27.745L51.397 4.16z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1288_14088"
            x1="34"
            x2="34"
            y1="-4"
            y2="481"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBFBFB"></stop>
            <stop offset="1" stopColor="#FBFBFB" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="9.87500rem"
        height="16.0625rem"
        fill="none"
        className="absolute bottom-0 hidden lg:block -z-10"
        viewBox="0 0 158 257"
      >
        <path
          fill="url(#paint0_linear_1288_14089)"
          fillOpacity="0.17"
          d="M-65.132 4.13a40.97 40.97 0 00-34.736 0l-159.72 74.759a40.968 40.968 0 00-22.517 27.74l-39.012 166.127a40.97 40.97 0 008.212 35.354l109.858 133.893a40.972 40.972 0 0031.673 14.982H6.374a40.97 40.97 0 0031.673-14.982L147.905 308.11a40.97 40.97 0 008.212-35.354l-39.012-166.127a40.969 40.969 0 00-22.517-27.74l-159.72-74.76z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1288_14089"
            x1="-82.5"
            x2="-82.5"
            y1="-4"
            y2="481"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBFBFB"></stop>
            <stop offset="1" stopColor="#FBFBFB" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18.12500rem"
        height="23.75rem"
        fill="none"
        className="absolute top-0 right-0 hidden lg:block -z-10"
        viewBox="0 0 290 380"
      >
        <path
          fill="url(#paint0_linear_1288_14092)"
          fillOpacity="0.17"
          d="M307.365-168.873a40.966 40.966 0 00-34.73 0L72.649-75.285a40.97 40.97 0 00-22.52 27.74L1.177 160.879a40.97 40.97 0 008.214 35.358l137.718 167.817a40.97 40.97 0 0031.671 14.979h222.442a40.97 40.97 0 0031.671-14.979L570.61 196.237a40.968 40.968 0 008.214-35.358L529.87-47.545a40.966 40.966 0 00-22.519-27.74l-199.986-93.588z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1288_14092"
            x1="290"
            x2="290"
            y1="-177"
            y2="408"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBFBFB"></stop>
            <stop offset="1" stopColor="#FBFBFB" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.87500rem"
        height="15.1875rem"
        fill="none"
        className="lg:hidden absolute -top-[4.601875rem] -right-[8.28125rem] -z-10"
        viewBox="0 0 254 243"
      >
        <path
          fill="url(#paint0_linear_1789_43772)"
          fillOpacity="0.17"
          d="M144.439 4.495a40.97 40.97 0 00-34.731 0L41.012 36.643a40.97 40.97 0 00-22.519 27.74L1.965 134.75a40.97 40.97 0 008.214 35.358l46.863 57.105a40.969 40.969 0 0031.67 14.98h76.723a40.97 40.97 0 0031.67-14.98l46.863-57.105a40.97 40.97 0 008.214-35.358l-16.528-70.369a40.971 40.971 0 00-22.519-27.739L144.439 4.495z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1789_43772"
            x1="127.074"
            x2="127.074"
            y1="-3.632"
            y2="255"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBFBFB"></stop>
            <stop offset="1" stopColor="#FBFBFB" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15.8125rem"
        height="15.125rem"
        fill="none"
        className="lg:hidden absolute -top-[9.4375rem] -right-[12.370625rem] -z-10"
        viewBox="0 0 253 242"
      >
        <path
          fill="url(#paint0_linear_1789_43771)"
          fillOpacity="0.15"
          d="M143.869 4.127a40.97 40.97 0 00-34.731 0L40.442 36.275a40.97 40.97 0 00-22.519 27.74L1.395 134.382a40.97 40.97 0 008.214 35.358l46.863 57.105a40.968 40.968 0 0031.67 14.979h76.722a40.97 40.97 0 0031.671-14.979l46.863-57.105a40.974 40.974 0 008.214-35.358l-16.528-70.369a40.971 40.971 0 00-22.519-27.74L143.869 4.128z"
        ></path>
        <defs>
          <linearGradient
            id="paint0_linear_1789_43771"
            x1="126.503"
            x2="126.503"
            y1="-4"
            y2="254.632"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FBFBFB"></stop>
            <stop offset="1" stopColor="#FBFBFB" stopOpacity="0"></stop>
          </linearGradient>
        </defs>
      </svg>
      <div className="container py-16 lg:py-32">
        <div className="w-full max-w-screen-md mb-8 text-neutral-inverse-12 text-5 font-bold sm:text-7 sm:mx-auto sm:text-center sm:mb-10">
          Get the freshest news and resources for developers, designers and
          digital creators in your inbox each week
        </div>
        <div
          ref={ref}
          id="email-octopus-form-embedded"
          className="flex justify-center align-center min-h-32 sm:min-h-16"
        >
          {!loaded && (
            <div className="text-neutral-inverse-12">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin" />{" "}
              Loading form
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
