import React from "react"
import { Slice } from "gatsby"

import Footer from "../footer-v2"
import Newsletter from "../newsletter"

export default function Layout({
  children,
  newsletter = true,
}: {
  children: React.ReactNode
  newsletter?: boolean
}) {
  return (
    <>
      <Slice alias="header" />
      <main>{children}</main>
      {newsletter && <Newsletter />}
      <Footer />
    </>
  )
}
